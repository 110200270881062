<template>
  <div>
    <div class="bg-splash">
      <form class="form modal-window" method="post" action="form.php" encType="multipart/form-data" ref="form" v-on:submit.prevent="sendForm">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-title">Заказать звонок</div>

        <div class="fields">

          <input type="hidden" name="form" value="callback">

          <div class="input-field">
            <label for="name">Ваше имя</label>
            <input type="text" name="phone" required id="name" v-model="name" ref="name" placeholder="Введите имя">
            <div class="input-info">Если возникнут дополнительные вопросы укажите по какому номеру мы сможем уточнить информацию</div>
          </div>

          <div class="input-field">
            <label for="phone">Ваш номер телефона</label>
            <input type="text" name="phone" required id="phone" v-mask="'+7 (###) ###-##-##'" value="+7 (" placeholder="Введите телефон" v-model="phone" ref="phone">
            <div class="input-info">
              Если возникнут дополнительные вопросы укажите по какому номеру мы сможем уточнить информацию
            </div>
          </div>

        </div>

        <div class="submit">

          <div class="policy">
            <input type="checkbox" name="policy" v-model="policy" id="callback-policy" required>
            <label for="callback-policy">
              согласен на обработку <a href="https://chmz.org/soglasie-na-obrabotku-personalnykh-dannykh/" target="_blank">персональных данных</a>
            </label>
          </div>
          <button type="submit">Заказать</button>
        </div>

      </form>
    </div>

  </div>
</template>

<script>
export default {

  props: [
    'model',
  ],

  data: function () {
    return {

      phone: '+7 (',
      name: null,

      policy: true,
    }
  },

  methods: {

    sendForm: function () {

      if (this.phone.length !== 18) {
        this.$refs.phone.focus();
        return false;
      }

      // eslint-disable-next-line no-undef
      axios.post("form.php", {
        form: 'callback',
        policy: this.policy,
        name: this.name,
        phone: this.phone,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            // eslint-disable-next-line no-prototype-builtins
            if (response.data.hasOwnProperty('status') && (response.data.status === true)) {
              this.$emit('after-send', response.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });

    },

    closeModal: function () {
      this.$emit('close');
    },

  },

}
</script>

<style lang="scss" scoped>
@import "./../styles/variables";

$input_height: 50px;

.form {
  max-width: 600px;
  width: 100%;

  .title {

  }

  .fields {
    .input-field {
      width: 100%;
      margin-bottom: 20px;

      label {
        margin-bottom: 8px;
      }

      input,
      select {
        width: 100%;
        height: $input_height;
        background-color: #fff;
        padding: 0 20px;
        font-weight: 400;
        font-size: 14px;
        color: $color_dark;

        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #A3A3A3;
        }
      }
    }
  }

  .submit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-family: $font_babas;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      height: $input_height;
      border: 2px solid $color_blue;
      color: $color_blue;
      padding: 0 15px;
      width: 100%;
      max-width: 388px;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

      &:hover {
        background-color: $color_blue;
        color: #fff;
      }
    }
  }

  .after-form {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-top: 33px;
  }
}

.file-box {
  height: $input_height;
  background-color: #fff;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
  }

  &:after {

  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #A3A3A3;
    position: absolute;
    z-index: 4;
    top: 12px;
    left: 20px;
  }

  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    cursor: pointer;
  }
}

.policy input + label {
  font-size: 14px;
}

@media screen and (max-width: 480px) {
  .modal-window {
    .modal-row {
      display: block;

      .modal-cell {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .form {
    .submit {
      display: block;

      .policy {
        margin-bottom: 20px;
      }
    }
  }

}
</style>